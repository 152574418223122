/* src/App.css */

.App {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  max-width: "100%";
  max-height: "100%";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* src/components/Keyboard.css */

.keyboard {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.keyboard-row {
  display: flex;
  justify-content: center;
}

.timeline {
  display: flex;
  align-items: flex-end; /* Align markers to the bottom of the line */
}

.line {
  flex-grow: 1; /* Let the line take remaining space */
  justify-content: center;
  align-items: top;
  height: 3px; /* Adjust line height as needed */
  background-color: #000; /* Set the line color */
  margin: 10 10; /* No margin for the line */
  width: 60%; /* Set the line width to occupy 60% of the available space */
}

.marker {
  width: 120px; /* Adjust marker width as needed */
  height: 120px; /* Adjust marker height as needed */
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: -10px; /* Adjust marker position to sit on the line */
}

.img {
  max-width: 100%;
  max-height: 100%;
}

.gif-container {
  text-align: center; /* Center the GIF horizontally */
}

.gif-image {
  height: 40dvh;
}
